/*
------------------------------------  WARNING  ------------------------------------
This file will be overwritten and should not be edited directly.
In order to edit custom CSS for Bold Product Upsell you should:
- Log into your Shopify Admin Panel
- Go to Apps --> Installed --> Product Upsell
- Go to Display Settings
------------------------------------  WARNING  ------------------------------------
*/
.bold-modal {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  display: none; }

  .bold-modal__window {
    box-sizing: border-box;
    padding: 30px;
    background-color: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto; }

    @media only screen and (max-width: 500px) {
      .bold-modal__window {
        height: 100%;
        padding: 20px;
      }
    }

  .bold-modal__window_content{
    height: 100%;
  }

  .bold-modal__btn-close {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    width: 40px;
    height: 40px;
    color: rgba(0,0,0,0.2);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 100000000; }

    @media only screen and (max-width: 500px) {
      .bold-modal__btn-close {
        width: 30px;
        height: 30px;
      }
    }

.bold-modal__btn-close:hover {
      opacity: 0.5; }




  .bold-modal__header {
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    padding: 0 70px 0 30px;
    margin: -30px -30px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

  .bold-modal__content {
    box-sizing: border-box;
    padding: 30px;
    margin: -30px -30px 0; }

    @media only screen and (max-width: 500px) {
      .bold-modal__content {
        padding: 20px;
        margin: -20px -20px 0;
        overflow: scroll;
        height: calc(100% - 27px); }
    }

    .bold-modal__header+.bold-modal__content {
      margin-top: 0; }

    .bold-modal__content:last-child {
      margin-bottom: -30px; }

  .bold-modal__footer {
    box-sizing: border-box;
    padding: 30px;
    margin: 0px -30px -30px; }

  @media only screen and (max-width: 500px) {
    .bold-modal__footer {
      width: 100%;
      position: absolute;
      margin: 0px -15px -15px;
      padding: 15px;
      bottom: 15px;
      left: 15px; }
  }

/* Showing and Hiding the Modal */
body.bold-modal--is-showing,
div.bold-modal--is-showing {
  overflow-y: hidden !important; }

body.bold-modal--is-showing .bold-modal,
div.bold-modal--is-showing .bold-modal {
  display: block; }

/* Transitions and Animations */
.bold-modal--animated {
  display: block;
  visibility: hidden;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease; }

.bold-modal--animated .bold-modal__window {
  top: 100vh;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  transition: 0.3s ease; }

body.bold-modal--is-showing .bold-modal--animated,
div.bold-modal--is-showing .bold-modal--animated {
  visibility: visible;
  opacity: 1; }

@media only screen and (min-width: 499px) {
  .bold-modal {
    background: rgba(0,0,0,0.8);
    overflow-y: auto; }

    .bold-modal__window {
      position: relative;
      margin: 40px; }

  .bold-modal--animated {
    opacity: 0; }

    .bold-modal--animated .bold-modal__window {
      top: 200px; }
}

@media only screen and (min-width: 879px) {
  .bold-modal__window {
    max-width: 800px;
    margin: 40px auto; }
}

/* bold.grid.css */
.bold-grid {
  box-sizing: border-box; }

.bold-grid:after {
  box-sizing: border-box;
  display: table;
  content: '';
  clear: both; }

.bold-grid__column {
  box-sizing: border-box;
  padding: 15px 15px 15px 0px;
  float: left;
}

@media only screen and (max-width: 500px) {
  .bold-grid__column {
    padding: 3px 8px 3px 0px;
  }
}

.bold-grid__column--half{
  width: 50%;
}

.bold-grid__column--third{
  width: 33.3333%;
}

@media only screen and (max-width: 700px) {

  .bold-grid__column--half,
  .bold-grid__column--third,
  .bold-grid__column--quarter {
    width: 100%; }

  .bold-grid__column--third:first-child {
    width: 100%;
  }


  .flickity-slider .bold-grid__column--half,
  .flickity-slider .bold-grid__column--third,
  .flickity-slider .bold-grid__column--quarter {
    width: 70%;
  }

  .flickity-slider .bold-grid__column--third:first-child {
    width: 70%;
  }

}



/* bold.grid.css */

/* bold.product.css */
.bold-product {
  box-sizing: border-box; }

.bold-product__image-container {
  box-sizing: border-box; }

.bold-product__image {
  box-sizing: border-box;
  display: inline-block;
  max-width: 100%;
  max-height: 300px;}

 /* BEGIN Trigger Product display styling  */
.bold-upsell__triger-product-container{
  display: flex;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.bold-upsell__triger-image-container{
  width: 85px;
  height: 85px;
}

.bold-upsell__triger-product-container .bold-product__info{
  width: calc(100% - 85px);
  display: inline-block;
  margin: 0 0 0 20px;
}
.bold-upsell__triger-product-container .bold-product__info div{
  max-height: 20px;
  margin-bottom: 3px;
}

.bold-upsell__triger-product-container .bold-product__info .bold-product__quantity{
  opacity: 0.7;
}

.bold-upsell__triger-product-container .bold-product__info .bold-product__title{
  max-height: 20px;
  overflow: hidden;
  margin-bottom: 0px;
}

.bold-upsell__triger-product-container .bold-product__info .bold-product__variant{
  opacity: 0.7;
}

.bold-upsell__triger-product-container .bold-product__info .bold-product__pricing{
  margin-top: 0px;
}

.bold-upsell__triger-product-container .bold-product__info .bold-product__price{
  font-size: 100%;
}
 /*---END Trigger Product display styling -----*/

.bold-product__control {
  box-sizing: border-box; }

.bold-product__variant-selector {
  font: inherit;
  width: 100%; }
/* bold.product.css */

/* bold.upsell.css */
.bold-upsell {
  box-sizing: border-box; }

.bold-upsell__intro {
  box-sizing: border-box; }

.bold-upsell__products-list {
  box-sizing: border-box; }

.bold-upsell__products-list .bold-grid__column {
  text-align: center; }

.bold-upsell__actions {
  text-align: right; }

.bold-upsell__button {
  box-sizing: border-box; }
/* bold.upsell.css */

.bold-grid__column--half .bold-product .bold-product__image,
.bold-grid__column--third .bold-product .bold-product__image {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.bold-grid__column--half .bold-product .bold-product__image-container,
.bold-grid__column--third .bold-product .bold-product__image-container {
  max-height: 300px;
  max-width: 300px;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  margin: 0 auto;
}

/*Options Modal stuff*/
#bold-modal-second__window #bold-modal__content .bold-product__info {
  display: inline-flex;
}

#bold-modal-second__window #bold-modal__content .bold-product__info #option_product_info{
  padding-left: 15px;
}

#bold-modal-second__window #bold-modal__content .bold-product__image {
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#bold-modal-second__window #bold-modal__content .bold-product__image {
  max-height: 75px;
  max-width: 75px;
  position: relative;
}

#bold-modal-second__window #bold-modal__content #bold_options {
  text-align: center;
}

#bold-modal-second__window #options_scroll_display{
  position: absolute;
  padding: 5px;
  border-radius: 25px;
  background-color: #3498db;
  font-size: 12px;
  left: 50%;
  bottom: 20px;
  border: 1px solid rgba(0,0,0,0.2);
  z-index: 2;
  color: #FFF;
  transform: translateX(-50%);
}

#second_window_back_btn {
  float: left;
  padding-top: 20px;
}

#loader {
  position: relative;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: 25px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes animatebottom {
  from { bottom:-100px; opacity:0 }
  to { bottom:0px; opacity:1 }
}

@keyframes animatebottom {
  from{ bottom:-100px; opacity:0 }
  to{ bottom:0; opacity:1 }
}


/*Nate Styles... or whatever*/

#bold-modal-second__window .bold-control-group__item{
  display: inline-block;
  width: initial;
}

#bold-modal-second__window .bold-product__control-label{
  float: left;
  margin: 15px 10px 0 0 ;
}

#bold-modal-second__window .bold-modal__footer .bold-product__quantity-field {
  width: 100px;
  float: right;
}

#bold-modal-second__window .scroll_visual::after{
  content: "";
  display: block;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,.4) 100%);
  width: 100%;
  height: 60px;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all .4s ease-in-out;
}

#bold-modal-second__window .bold-upsell__products-list{
  position: relative;
}

@media screen and (max-width: 500px){

  #bold-modal-second__window .bold-control-group__item{
    width: 100%;
    margin-bottom: 10px;
  }

}


/* =============================================================================
  Responsive Slider Styles
============================================================================= */

.bold-modal__slider{
  position: relative;
}

.bold-modal__slider:after{
  display: none;
  width: 200px;
  height: 100%;
  position: absolute;
  content: "";
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
  top: 0px;
  right: -15px;
  opacity: 1;
  pointer-events: none;
  z-index: 10;
  transition: opacity .4s ease-in-out;
}

@media screen and (max-width: 699px){
  .bold-modal__slider:after{
    display: none;
  }
}

.bold-modal__slider .flickity-prev-next-button{
  z-index: 11;
  background: #adadad !important;
  width: 32px !important;
  height: 32px !important;
  opacity: .85 !important;
  transition: opacity .4s ease-in-out;
  top: 40% !important;
}

.bold-modal__slider .flickity-prev-next-button svg{
  width: 40% !important;
  left: 29% !important;
}

.bold-modal__slider .flickity-prev-next-button svg *{
  fill: #fff !important;
}

.bold-modal__slider .flickity-prev-next-button:disabled{
  opacity: 0 !important;
}

.bold-modal__slider .next{
  right: -5px !important;
}

.bold-modal__slider .previous{
  left: -5px !important;
}

.bold-modal__slider .flickity-page-dots{
  bottom: -15px !important;
}

@media screen and (max-width: 499px){
  .bold-modal__window{
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    top: 20px !important;
    left: 20px;
  }
}



/* Options second window */

#bold-modal-second__window .bold_option {
    display: flex;
    margin-bottom: 16px;
    text-align: left;
    padding: 0 40px;
}

@media screen and (max-width: 600px){
  #bold-modal-second__window .bold_option {
      padding: 0 15px;
  }
}

#bold-modal-second__window .bold_option_title {
    flex: 1 0 35%;
    padding-right: 20px;
}

#bold-modal-second__window .bold_option_element {
    flex: 1 0 65%;
    flex-wrap: wrap;
}

#bold-modal-second__window .bold_option_dropdown label {
    display: flex;
    width: 100%;
}

#bold-modal-second__window .bold_option_element label {
    display: block;
    margin-bottom: 10px;
}

#bold-modal-second__window .bold_option_element select {
    width: 100%;
}

#bold-modal-second__window .bold_option_product_title {
    display: none;
}

#bold-modal-second__window .bold_option_textbox label {
    width: 100%;
    display: flex;
}



#bold-modal-second__window .bold_option_textbox .bold_option_element {
    display: block;
    width: 65%;
}

#bold-modal-second__window .bold_option_textbox .bold_option_element input {
    width: 100%;
}



#bold-modal-second__window .bold_option_radio input {
    margin-right: 8px;
}

#bold-modal-second__window .bold_option_title {
    display: block;
    width: 35%;
}

#bold-modal-second__window .bold_option_value {
    margin-right: 0;
    display: block;
    width: 100%;
}

#bold-modal-second__window .bold_option_value label {
    display: flex;
}

#bold-modal-second__window .with-options {
    font-size: 16px;
    padding: 0px;
    margin: 0;
    width: 100%;
    border: 0;
    overflow-x: hidden;
}


#second_window_back_btn {
    float: left;
    padding-top: 14px;
  }

#bold-modal-second__window .scroll_visual::after {
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,.14) 100%);
}

#bold-modal-second__window .bold-modal__content {
    padding: 0;
}

#bold-modal-second__window .bold-upsell__intro {
    padding: 10px 30px 10px;
}

#bold-modal-second__window .bold-upsell__intro .bold-product__title {
    font-size: 18px;
}

#bold-modal-second__window .bold-upsell__intro .bold-product__price {
    font-size: 16px;
}

#bold-modal-second__window .bold-upsell__actions {
    padding: 20px 30px
;
}

#bold-modal-second__window .bold-upsell__actions .bold-upsell__button--primary {
    padding: 10px 20px
;
}

#bold-modal-second__window .bold-upsell__products-list {
    padding: 30px 0px 0;
    margin: 0;
    border-top: 1px solid rgba(0,0,0,.16);
    border-bottom: 1px solid rgba(0,0,0,.16);
}

#bold-modal-second__window #bold-modal__content .bold-product__info {
    display: flex;
    margin: 0;
}
#bold-modal-second__window .bold_option_checkbox label{
  display: flex;
  width: 100%;
}
#bold-modal-second__window .bold_option_checkbox .bold_option_title{

}

#bold-modal-second__window .bold_option_checkbox .bold_option_element {
    width: 35px;
    flex: 1 0 35px;
}


@media screen and (max-width: 600px){
  #bold-modal-second__window .bold_option_checkbox .bold_option_title {
      flex: 1 0 70%;
  }

  #bold-modal-second__window .bold-upsell__products-list{
    padding: 10px;
  }
}

#bold-modal-second__window .bold_option_value_price::before {
    content: "+";
}

#bold-modal-second__window #options_scroll_display {
    border: 0;
    padding: 5px 12px;
    box-shadow: 0px 4px 8px rgba(0,0,0,.1);
    /*animation: jiggle 3s linear infinite;*/
    bottom: 12px;
}

#bold-upsell__button--primary {
    padding-top: 14px;
}

#bold-modal-second__window .bold_option_swatch .bold_option_element{
    display: flex;
    flex-wrap: wrap;
}

#bold-modal-second__window .bold_option_swatch .bold_option_value{
    display: block;
    width: 40px;
  	margin-right: 10px;
  	margin-bottom: 10px;

}

#bold-modal-second__window .bold_option_dropdownmulti label{
	width: 100%;
	display: flex;
}


/* Product options: Textarea */

#bold-modal-second__window .bold_option_textarea label {
    width: 100%;
    display: flex;
}

#bold-modal-second__window .bold_option_textarea .bold_option_title {
    flex: 1 0 35%;
}

#bold-modal-second__window .bold_option_textarea .bold_option_element {
    flex: 1 0 65%;
}

#bold-modal-second__window .bold_option_textarea textarea {
    width: 100%;
    max-width: 100%;
}


/* Product options: Textbox multi */

#bold-modal-second__window .bold_option_textboxmulti{
  flex-wrap: wrap;
}

#bold-modal-second__window .bold_option_textboxmulti .bold_option_title{
    flex: 1 0 35%;
}

#bold-modal-second__window .bold_option_textboxmulti .bold_option_element{
    flex: 1 0 65%;
}

#bold-modal-second__window .bold_option_textboxmulti label{
  flex-wrap: wrap;
}

#bold-modal-second__window .bold_option_textboxmulti .bold_help_text{
  flex: 1 0 100%;
  padding-left: 35%;
}

#bold-modal-second__window .bold_option_textboxmulti .bold_option_value_title{
  display: block;
  flex: 1 0 100%;
}

#bold-modal-second__window .bold_option_textboxmulti .bold_option_value_element{
  display: block;
  flex: 1 0 100%;
}

#bold-modal-second__window .bold_option_textboxmulti .bold_option_value_element input{
  width: 100%;
}


/* Product options: Number */

#bold-modal-second__window .bold_option_number label{
  display: flex;
  width: 100%;
}

#bold-modal-second__window .bold_option_number input{
  width: 100%;
}


/* Product options: Email */

#bold-modal-second__window .bold_option_email label{
  display: flex;
  width: 100%;
}

#bold-modal-second__window .bold_option_email input{
  width: 100%;
}



/* Product options: Color */

#bold-modal-second__window .bold_option_color label{
  display: flex;
  width: 100%;
}


/* Product options: Date */

#bold-modal-second__window .bold_option_date label{
  display: flex;
  width: 100%;
}

#bold-modal-second__window .bold_option_date input{
  width: 100%;
}


/* Product options: Telephone */

#bold-modal-second__window .bold_option_telephone label{
  display: flex;
  width: 100%;
}

#bold-modal-second__window .bold_option_telephone input{
  width: 100%;
}


/* bold options file upload */

#bold-modal-second__window .bold_option_uploadfile label{
  display: flex;
  width: 100%;
}

#bold-modal-second__window .bold_option_uploadfile input{
  width: 100%;
}

@keyframes jiggle {
  0% {
    transform: translate(-50%,0);
  }
  65% {
    transform: translate(-50%,0);
  }
  70% {
    transform: translate(-50%,5px);
  }
  75% {
    transform: translate(-50%,-4px);
  }
  80% {
    transform: translate(-50%,3px);
  }
  85% {
    transform: translate(-50%,-2px);
  }
  90% {
    transform: translate(-50%,1px);
  }
  95% {
    transform: translate(-50%, 0px);
  }
}

/* =============================================================================
  THIRD PARTY APPS STYLES
============================================================================= */

#bold-upsell-modal-window .yotpo{
  display: flex;
  justify-content: center;
}

#bold-upsell-modal-window .yotpo:empty{
  display: none;
}

.bold-upsell__products-list {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.bold-upsell__products-list.loading {
  transform: translate(-150%);
  -webkit-transform: translate(-150%);
}



/* =============================================================================
  MODAL STYLES
============================================================================= */
.bold-modal {
  background-color: rgba(0,0,0,0.8); }

  .bold-modal__window {
    box-shadow: 0px 5px 15px rgba(0,0,0,0.2);
    border-radius: 5px; }

  .bold-modal__header {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0,0,0,0.1); }

  .bold-modal__footer {
    border-top: 1px solid rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.02); }


/* =============================================================================
  UPSELL STYLES
============================================================================= */
.bold-upsell {}

  .bold-upsell__intro {
    font-size: 12px;
    line-height: 14px; }

  .bold-upsell__intro p:last-child{
    margin: 0px 0px 5px 0px; }

  .bold-upsell__intro-heading {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 5px; }

  .bold-upsell__intro-content{
    margin: 0px 0px 5px 0px; }

.bold-upsell__button--primary:link,
.bold-upsell__button--primary:visited,
.bold-upsell__button--primary:hover,
.bold-upsell__button--primary:active,
.bold-upsell__button--primary:focus {
  color: inherit;
  text-decoration: none; }

.bold-upsell__button--primary {
  display: inline-block;
  padding: 15px 25px;
  border: 1px solid rgba(0,0,0,0.3);
  background-color: rgba(0,0,0,0.03);
  border-radius: 2px; }

  .bold-upsell__button--primary:hover {
    background-color: rgba(0,0,0,0);
    border-color: rgba(0,0,0,0.2); }

  .bold-upsell__button--primary:active {
    background-color: rgba(0,0,0,0.05);
    border-color: rgba(0,0,0,0.4); }

.bold-upsell__button--secondary {
  margin-right: 20px;
  font-size: 90%; }

@media screen and (max-width: 500px){
  .bold-upsell__button--primary {
    padding: 5px 25px; }
}


/* =============================================================================
  PRODUCT STYLES
============================================================================= */
.bold-product {
  max-width: auto;
  font-size: 13px;
  background-color: rgba(255,255,255, 0.5);
  border: 1px solid rgba(0,0,0,0.2);
  padding: 10px; }

  .bold-product.bold-grid {
    margin-left: 0;
    margin-right: 0; }

  .bold-product__info,
  .bold-product__variants,
  .bold-product__actions {
    margin: 10px 0; }


  @media only screen and (min-width: 700px) {
    .bold-product__above_content_button_container {
      display: none;
    }
  }

  @media only screen and (max-width: 699px) {
    .bold-product__below_content_button_container {
      display: none;
    }
  }

  .bold-product__pricing {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }

  .bold-upsell__triger-product-container .bold-product__pricing{
    display: block;
  }

  @media only screen and (max-width: 500px) {
    .bold-product__pricing {
      margin: 10px 0; }
  }

  .bold-product__title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center; }

  .bold-upsell__triger-product-container .bold-product__title{
    display: block;
  }

  @media only screen and (max-width: 500px) {
    .bold-product__title {
      font-size: 12px; }
  }

  .bold-product__title_content {

  }

  .bold-product__description {
    opacity: 0.6; }

.bold-product__price {
  display: inline;
  font-weight: bold;
  line-height: 1;
  font-size: 150%; }

  .bold-product__price.current_price {
    line-height: 1.3; }

.bold-product__message {
  opacity: 0.4; }

  .bold-product__price--deleted {
    font-weight: normal;
    font-size: 90%;
    opacity: 0.4;
    margin: 0px 3px;
    text-decoration: line-through; }

  .bold-product__price--deleted:empty{
    margin: 0px 0px;
  }

.bold-control-group {
  box-sizing: border-box;
  display: block;
  width: 100%; }

.bold-control-group__item {
  display: block;
  vertical-align: bottom;
  text-align: left;
  width: 100%; }

.bold-product__control {
  padding: 15px;
  display: block;
  border-radius: 2px;
  line-height: 1;
  color: inherit;
  border: 1px solid rgba(0,0,0,0.3);
  background-color: rgba(0,0,0,0.03);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

@media only screen and (max-width: 500px) {
  .bold-product__control {
    padding: 5px 15px; }
}

.bold-product__control-label {
  display: inline-block;
  margin-bottom: 3px; }

.bold-product__variant-selector {
  padding-right: 36px;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16"><path fill="CurrentColor" d="M8.02682426,8.99999532 L11.3523243,8.99999532 C11.7765243,8.99999532 12.0080243,9.49499532 11.7360243,9.82059532 L10.2242243,11.6301953 L8.41062426,13.8032953 C8.31564065,13.9171173 8.17504521,13.9829213 8.02679765,13.9829406 C7.87855009,13.9829599 7.73793751,13.9171926 7.64292426,13.8033953 L5.82942426,11.6315953 L4.31712426,9.82049532 C4.04532426,9.49489532 4.27682426,8.99999532 4.70102426,8.99999532 L8.02702426,8.99999532 L8.02682426,8.99999532 Z M8.02652426,6.98299532 L4.70102426,6.98299532 C4.27682426,6.98299532 4.04532426,6.48799532 4.31732426,6.16229532 L5.82902426,4.35269532 L7.64262426,2.17969532 C7.73759304,2.06586091 7.8781799,2.00003864 8.02642747,2.00000002 C8.17467503,1.9999614 8.31529617,2.06571041 8.41032426,2.17949532 L10.2238243,4.35129532 L11.7361243,6.16239532 C12.0079243,6.48799532 11.7764243,6.98289532 11.3523243,6.98289532 L8.02632426,6.98289532 L8.02652426,6.98299532 Z"/></svg>');
  background-repeat: no-repeat;
  background-position: right center;
  background-color: transparent;
  height: auto;
}

  .bold-product__actions {
    margin-bottom: 0; }

  .bold-product__button,
    .bold-product__button:link,
    .bold-product__button:visited,
    .bold_product__button:hover,
    .bold_product__button:active,
    .bold_product__button:focus {
      text-decoration: none;
      color: inherit; }

    .bold-product__button:hover {
      background-color: rgba(0,0,0,0);
      border-color: rgba(0,0,0,0.2); }

    .bold-product__button:active {
      background-color: rgba(0,0,0,0.05);
      border-color: rgba(0,0,0,0.4); }

.bold-product__quantity-field{
  width: 100%;
  background-color: transparent;
  font-size: 13px;
  line-height: 14px;
}

#bold-modal-first__window .bold-product__quantity-field{
  margin: 10px 0px;
}

/* custom css */

